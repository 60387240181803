const api = {
  // Login: '/sys/portalLogin',
  Login: '/sys/wxPortalLogin',
  Logout: '/auth/logout',
  ForgePassword: '/auth/forge-password',
  Register: '/auth/register',
  twoStepCode: '/auth/2step-code',
  SendSms: '/account/sms',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/user/info',
  smsCode: '/sys/register/sms-code', // 发送验证码
  Essentialregister: '/sys/users-anon/register', // 基本信息注册
  RestPwdSimple: '/sys/user/passwordChangeWx' // 找回密码
}
export default api
