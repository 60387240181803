import router from './router'
import store from './store'

import NProgress from 'nprogress' // progress bar
import { getCookie } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

// 无需登录白名单列表
const whiteList = [
  // 登录
  'login',
  // 注册
  'register',
  // 找回密码
  'rest-password',
  // 园区简介
  'introduction',
  // 关于我们
  'contactUs',
  // 入住指南
  'checkinGuide',
  // 活动
  'activity',
  // 活动详情
  'activityDetail',
  // 动态
  'dynamic',
  // 动态详情
  'dynamicDetail'
]
const defaultRoutePath = 'property'

router.beforeEach(async (to, from, next) => {
  console.log('去向', to.name, '来源', from.name, 'time', new Date().getTime())
  NProgress.start() // start progress bar
  document.title = `${to.meta.title}`
  const token = getCookie(ACCESS_TOKEN)
  if (token) {
    if (to.path === '/login') {
      next({ path: defaultRoutePath, replace: true })
      NProgress.done()
    } else {
      store
        .dispatch('GetInfo')
        .then(() => {
          console.log('取到用户信息,进行下一步', to.path)
          next()
        })
        .catch(() => {
          store.dispatch('Logout').then(() => {
            console.log('没有取到用户信息,退出,登录')
            next({ path: '/login', replace: true })
          })
        })
    }
  } else {
    if (whiteList.includes(to.name)) {
      console.log('白名单访问')
      next() // 在免登录白名单，直接进入
    } else {
      console.log('无Token跳login页')
      next({ path: '/login', replace: true })
    }
  }
})
router.onError((res) => {
  console.log(res)
})

router.afterEach(() => {
  console.log('页面加载完毕')
  NProgress.done() // finish progress bar
})
