// eslint-disable-next-line
import { BlankLayout } from '@/layouts'
// import store from '../store'
export const asyncRouterMap = []

/**
 * 基础路由
 * 需求登录的页面 统一以 /admin开头
 * @type { *[] }
 */
export const constantRouterMap = [
  // 注册页面
  {
    path: '/',
    name: 'index',
    component: BlankLayout,
    redirect: 'login',
    children: [
      {
        path: 'bind-login',
        name: 'bind-login',
        meta: { title: '登录', keepAlive: false },
        component: () => import('@/views/login/WechatBind')
      },
      {
        path: 'login',
        name: 'login',
        meta: { title: '登录', keepAlive: false },
        component: () => import('@/views/login/Login')
      },
      {
        path: 'register',
        name: 'register',
        meta: { title: '注册', keepAlive: false },
        component: () => import('@/views/login/Register')
      },
      {
        path: 'rest-password',
        name: 'rest-password',
        meta: { title: '找回密码', keepAlive: false },
        component: () => import('@/views/login/ResetPassword')
      },
      {
        path: 'news-detail',
        name: 'news-detail',
        meta: { title: '公告详情', keepAlive: false },
        component: () => import('@/views/news/NewsDetail')
      },
      // 需求详情
      {
        path: '/project/detail/:projectId',
        name: 'project-detail',
        meta: { title: '需求详情', keepAlive: false },
        component: () => import('@/views/project/Detail')
      },
      // 需求管理
      {
        path: '/admin/project',
        name: 'admin-project',
        meta: { title: '需求管理', keepAlive: false },
        component: () => import('@/views/project/admin/Index')
      },
      // 需求对接
      {
        path: '/admin/project/detail/:projectId',
        name: 'admin-project-detail',
        meta: { title: '需求对接', keepAlive: false },
        component: () => import('@/views/project/admin/Detail')
      },
      // 需求添加/编辑
      {
        path: '/admin/project/edit/:projectId',
        name: 'admin-project-edit',
        meta: { title: '需求编辑', keepAlive: false },
        component: () => import('@/views/project/admin/Edit')
      },
      {
        path: '/job-test',
        name: 'job-test',
        component: () => import('@/views/me/JobTest')
      },
      {
        path: '/my-qr-code',
        name: 'my-qr-code',
        component: () => import('@/views/me/Myqrcode')
      },
      // 设置
      {
        path: '/settings/home',
        name: 'settings-home',
        meta: { title: '设置', keepAlive: false },
        component: () => import('@/views/me/settings/Home')
      },
      {
        path: '/settings/restpwdsms',
        name: 'settings-restpwdsms',
        meta: { title: '修改密码', keepAlive: false },
        component: () => import('@/views/me/settings/RestPwdSms')
      },
      {
        path: '/feedback/list',
        name: 'feedback-list',
        meta: { title: '意见反馈', keepAlive: false },
        component: () => import('@/views/me/feedback/List.vue')
      },
      {
        path: '/feedback/add',
        name: 'feedback-add',
        meta: { title: '意见反馈', keepAlive: false },
        component: () => import('@/views/me/feedback/Add.vue')
      },
      {
        path: '/feedback/detail',
        name: 'feedback-detail',
        meta: { title: '意见反馈', keepAlive: false },
        component: () => import('@/views/me/feedback/Detail.vue')
      },
      {
        path: '/feedback/edit',
        name: 'feedback-edit',
        meta: { title: '意见反馈', keepAlive: false },
        component: () => import('@/views/me/feedback/Edit.vue')
      },
      {
        path: '/template',
        name: 'template',
        meta: { title: '详情页', keepAlive: false },
        component: () => import('@/views/me/Template')
      },
      // 园区
      {
        path: '/introduction',
        name: 'introduction',
        meta: { title: '园区简介', keepAlive: false },
        component: () => import('@/views/park/Introduction')
      },
      {
        path: '/dynamic/list',
        name: 'dynamic',
        meta: { title: '园区动态', keepAlive: false },
        component: () => import('@/views/park/dynamic/List')
      },
      {
        path: '/dynamic/Detail/:id',
        name: 'dynamicDetail',
        meta: { title: '园区动态', keepAlive: false },
        component: () => import('@/views/park/dynamic/Detail')
      },
      {
        path: '/activity/list',
        name: 'activity',
        meta: { title: '园区活动', keepAlive: false },
        component: () => import('@/views/park/activity/List')
      },
      {
        path: '/activity/detail/:id',
        name: 'activityDetail',
        meta: { title: '园区活动', keepAlive: false },
        component: () => import('@/views/park/activity/Detail')
      },
      {
        path: '/checkinGuide',
        name: 'checkinGuide',
        meta: { title: '入驻指南', keepAlive: false },
        component: () => import('@/views/park/CheckinGuide')
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        meta: { title: '联系我们', keepAlive: false },
        component: () => import('@/views/park/ContactUs')
      },
      // 机构
      {
        path: '/enterprise',
        name: 'enterprise',
        meta: { title: '企业服务', keepAlive: false },
        component: () => import('@/views/enterprise/Home')
      },
      {
        path: '/enterprise/repair/list',
        name: 'repairList',
        meta: { title: '物业报修', keepAlive: false },
        component: () => import('@/views/enterprise/repair/List')
      },
      {
        path: '/enterprise/repair/add',
        name: 'repairAdd',
        meta: { title: '新增报修单', keepAlive: false },
        component: () => import('@/views/enterprise/repair/Add')
      },
      {
        path: '/enterprise/repair/edit',
        name: 'repairEdit',
        meta: { title: '修改报修单', keepAlive: false },
        component: () => import('@/views/enterprise/repair/Edit')
      },
      {
        path: '/enterprise/repair/detail',
        name: 'repairDetail',
        meta: { title: '查看报修详情', keepAlive: false },
        component: () => import('@/views/enterprise/repair/Detail')
      },
      {
        path: '/enterprise/cost',
        name: 'cost-me',
        meta: { title: '费用查看', keepAlive: false },
        component: () => import('@/views/enterprise/Cost')
      },
      {
        path: '/enterprise/demand/list',
        name: 'demand',
        meta: { title: '需求大厅', keepAlive: false },
        component: () => import('@/views/enterprise/demand/List')
      },
      {
        path: '/enterprise/demand/detail/:projectId',
        name: 'demand-detail',
        meta: { title: '需求详情', keepAlive: false },
        component: () => import('@/views/enterprise/demand/Detail')
      }
    ]
  }
]
