import api from './index'
import { axios } from '@/utils/request'
import qs from 'qs'

export function login(parameter) {
  return axios({
    url: '/api-uaa/oauth/user/token',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      Authorization: 'Basic d2ViQXBwOndlYkFwcA==', // window.btoa('webApp:webApp')
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function loginFormSys(parameter) {
  return axios({
    url: api.Login,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

// 获取用户基本信息，包括菜单权限
export function getInfo() {
  return axios({
    url: '/sys/register/base-info',
    method: 'get'
  })
}
// 注册获取验证码
export function getCode(parameter) {
  return axios({
    url: '/sys/sms',
    method: 'post',
    data: parameter
  })
}

// 修改密码获取验证码
export function getPassCode(parameter) {
  return axios({
    url: '/sys/sms',
    method: 'post',
    data: parameter
  })
}

// 基本信息注册
export function Essentialregister(parameter) {
  return axios({
    url: '/sys/register/users-anon',
    method: 'post',
    data: parameter
  })
}

// 找回密码
export function RestPwdSimple(parameter) {
  return axios({
    url: api.RestPwdSimple,
    method: 'get',
    params: parameter
  })
}

// 获取消息通知未读数
export function getUnreadNum(parameter) {
  return axios({
    url: '/sys/annountCement/listByUser',
    method: 'get',
    params: parameter
  })
}

// 企业入驻信息完善获取信息
export function getorginfo(parameter) {
  return axios({
    url: '/business/tbOrg/org/org-info/' + parameter,
    method: 'get'
  })
}

// 企业入驻信息完善提交
export function personinfo(parameter) {
  return axios({
    url: '/sys/register/user-info',
    method: 'post',
    data: parameter
  })
}

export function sysLogout(logoutToken) {
  return axios({
    url: '/sys/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Access-Token': logoutToken
    }
  })
}
