import { axios } from '@/utils/request'
import qs from 'qs'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 *
 * @param parameter
 * @returns {*}
 */

// 收藏
export function actionsSwitch(parameter) {
  return axios({
    url: '/business/tbSysFavorite/actions/switch',
    method: 'post',
    data: parameter
  })
}

export function uploadFile(parameter) {
  return axios({
    url: '/api-file/public/upload/public',
    method: 'post',
    data: parameter
  })
}

export function uploadBase64(parameter) {
  return axios({
    url: '/api-file/public/upload/base64-v2',
    method: 'post',
    data: qs.stringify(parameter)
  })
}
// 创建或取消收藏
export function switchFavorite(data) {
  return axios({
    url: '/business/tbSysFavorite/actions/switch',
    method: 'post',
    data
  })
}
// 查询模板详情
export function template(id) {
  return axios({
    url: '/news/tbOpTemplate/public/template/' + id,
    method: 'get'
  })
}

// 背景图列表
export function banner() {
  return axios({
    url: '/news/tbOpBanner/public/list',
    method: 'get'
  })
}

// 获取全部字典数据
export function queryAllDictItems(params) {
  return axios({
    url: '/sys/dict/queryAllDictItems',
    method: 'get',
    params
  })
}
